@charset "UTF-8";
/* Reset */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, textarea, p, blockquote, th, td, input, select, textarea, button {
  margin: 0;
  padding: 0; }

fieldset, img {
  border: 0 none; }

dl, ul, ol, menu, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

input, select, textarea, button {
  vertical-align: middle; }

input::-ms-clear {
  display: none; }

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer; }

body, h1, h2, h3, h4, th, td, input, select, textarea, button {
  font-size: 13px;
  color: #333; }

a {
  color: #333;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0; }

a:active, a:hover {
  text-decoration: underline; }

address, caption, cite, code, dfn, em, var {
  font-style: normal;
  font-weight: normal; }

header, main, aside, article, footer {
  display: block; }

.hr_page .w960 {
  width: 960px;
  margin: 0 auto; }

/* header */
.pg_hd_wrap {
  position: relative;
  background-color: #33c24d; }

.pg_rolling {
  position: relative;
  background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/sec_1_img.png) no-repeat 0 0;
  background-size: cover;
  height: 520px; }

/* content */
.pg_bd_wrap dl {
  display: block;
  margin: 0;
  box-sizing: border-box;
  padding-top: 116px; }

.pg_bd_wrap dt {
  display: block;
  font-size: 42px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #333333;
  margin: 0 auto 15px; }
  .pg_bd_wrap dt strong {
    font-weight: normal; }

.pg_bd_wrap dd {
  display: block;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: -0.4px;
  text-align: center;
  color: #333333;
  margin: 0 auto; }
  .pg_bd_wrap dd strong {
    font-weight: normal; }

.rolling_wrap {
  position: relative;
  width: 960px;
  height: 520px;
  margin: 0 auto; }
  .rolling_wrap .rolling_group {
    position: absolute;
    left: 0;
    top: 100px;
    display: block;
    width: 960px;
    height: 300px;
    overflow: hidden;
    z-index: 1; }
    .rolling_wrap .rolling_group .box {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      z-index: 3;
      width: 960px;
      height: 300px;
      text-align: center; }
      .rolling_wrap .rolling_group .box a:hover {
        text-decoration: none; }
      .rolling_wrap .rolling_group .box img {
        width: 835px; }
  .rolling_wrap .rolling_indicator {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    text-align: center; }
  .rolling_wrap .card_set {
    position: absolute;
    top: 0;
    left: 0; }

.btn_bg_blue {
  border: 1px solid #219BF0;
  background-color: #219BF0; }
  .btn_bg_blue:hover {
    border: 1px solid #219BF0;
    background-color: #268ad1; }

.company_pv_chart_box .section_hd {
  padding-bottom: 0; }

.company_pv_chart_box .company_pv_chart_description {
  margin-top: 15px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal; }
  .company_pv_chart_box .company_pv_chart_description .date {
    color: #33c24d; }

/* 제휴대학 만족도 survey page */
.satisfaction_survey_page {
  padding-top: 100px;
  padding-bottom: 100px; }
  .satisfaction_survey_page .top_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-color: #c2c8cc;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover; }

.jp_survey_wrap {
  position: relative;
  width: 645px;
  margin: 0 auto;
  padding: 60px 73px 60px 72px;
  z-index: 1;
  background-color: #fff;
  box-sizing: border-box; }

.jp_survey_header {
  position: relative;
  padding-top: 67px;
  padding-bottom: 30px;
  border-bottom: 2px solid #d6d6d6; }
  .jp_survey_header .logo_wrap {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 27px;
    overflow: hidden; }
    .jp_survey_header .logo_wrap > img {
      width: auto;
      height: 100%; }
  .jp_survey_header .survey_title {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.46;
    text-align: center;
    color: #424242; }
  .jp_survey_header .description {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #757575; }

.jp_survey_content .survey_button_group {
  margin-top: 68px;
  text-align: center; }
  .jp_survey_content .survey_button_group > .btn_ty2 {
    width: 220px;
    display: inline-block;
    background-color: #219bf0; }

.jp_question_item {
  padding: 48px 0 50px; }
  .jp_question_item .question_header {
    width: 100%;
    padding: 0 40px;
    word-break: break-all;
    box-sizing: border-box; }
    .jp_question_item .question_header .q_title {
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 1.44;
      color: #424242;
      text-align: center;
      word-break: keep-all; }
    .jp_question_item .question_header .q_guide {
      display: block;
      font-size: 12px;
      line-height: 1.5;
      color: #757575;
      text-align: center;
      word-break: keep-all; }
  .jp_question_item .question_box {
    position: relative;
    padding: 68px 40px 0; }
  .jp_question_item .react_error_txt {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 12px; }
  .jp_question_item .is_error .react_error_txt {
    display: block; }
  .jp_question_item .input_ty1::placeholder,
  .jp_question_item .txtarea_ty1::placeholder {
    color: #bdbdbd; }
  .jp_question_item .input_ty1::-webkit-input-placeholder,
  .jp_question_item .txtarea_ty1::-webkit-input-placeholder {
    color: #bdbdbd; }
  .jp_question_item .input_ty1:-moz-placeholder,
  .jp_question_item .txtarea_ty1:-moz-placeholder {
    color: #bdbdbd; }
  .jp_question_item .input_ty1::-moz-placeholder,
  .jp_question_item .txtarea_ty1::-moz-placeholder {
    color: #bdbdbd; }
  .jp_question_item .input_ty1:-ms-input-placeholder,
  .jp_question_item .txtarea_ty1:-ms-input-placeholder {
    color: #bdbdbd; }
  .jp_question_item .iptwrap_ty1 .input_ty1 {
    padding: 0 13px; }
  .jp_question_item .iptwrap_ty1 .txtarea_ty1 {
    min-height: 150px; }
  .jp_question_item .question_box_radio,
  .jp_question_item .question_box_checkbox {
    padding: 0; }
  .jp_question_item .cssel_ty2 .btn_sel {
    color: #bdbdbd;
    font-size: 13px; }
  .jp_question_item .btn_radio,
  .jp_question_item .fc_check {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px; }
    .jp_question_item .btn_radio:first-child,
    .jp_question_item .fc_check:first-child {
      margin-top: 0; }
  .jp_question_item .btn_radio .radio_txt {
    font-size: 13px; }

.cstextarea {
  width: 100%;
  height: 150px;
  padding: 10px 13px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal; }

/* navigation */
.jp_lnb,
.jp_tabmenu_full {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }
  .jp_lnb .inner_grid,
  .jp_tabmenu_full .inner_grid {
    position: relative;
    display: block;
    width: 960px;
    margin: 0 auto; }
    .jp_lnb .inner_grid:after,
    .jp_tabmenu_full .inner_grid:after {
      display: block;
      content: '';
      clear: both; }
  .jp_lnb .navigatior_wrap,
  .jp_tabmenu_full .navigatior_wrap {
    display: table;
    height: 54px; }
  .jp_lnb .table_row,
  .jp_tabmenu_full .table_row {
    display: table-row; }
  .jp_lnb .nav_item,
  .jp_tabmenu_full .nav_item {
    position: relative;
    display: table-cell;
    min-width: 148px;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    vertical-align: middle;
    text-align: center;
    color: #bababa;
    box-sizing: border-box; }
    .jp_lnb .nav_item .tit,
    .jp_tabmenu_full .nav_item .tit {
      font-size: 15px;
      color: #b9b9b9;
      line-height: 19px; }
    .jp_lnb .nav_item.active .tit,
    .jp_tabmenu_full .nav_item.active .tit {
      color: #666; }
    .jp_lnb .nav_item:hover,
    .jp_tabmenu_full .nav_item:hover {
      text-decoration: none; }
    .jp_lnb .nav_item.side,
    .jp_tabmenu_full .nav_item.side {
      position: absolute;
      top: 0;
      right: 0;
      padding: 17px 10px 18px; }

.jp_lnb {
  z-index: 100; }
  .jp_lnb.sticky {
    position: fixed;
    top: 52px; }

.employ_center_home .nav_1 .tit,
.employ_center_list .nav_2 .tit,
.employ_center_shop .nav_3 .tit,
.employ_center_my .nav_4 .tit {
  color: #666; }

.jp_employ_slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;
  border-bottom: 0;
  box-sizing: border-box; }
  .jp_employ_slider .inner_slider_wrap {
    position: relative; }
  .jp_employ_slider .rolling_indicator {
    bottom: 16px; }

.employ_indicator_item {
  overflow: hidden;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
  text-indent: -9999px;
  background-color: #dbdbdb;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(140, 140, 140, 0.5);
  cursor: pointer; }
  .employ_indicator_item.on {
    background-color: #fff; }

/* icon */
.ico_lecture_vod {
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/43/video_icon_01.png") no-repeat 0 0; }

.ico_lecture_vod_g {
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/45/video_icon_03.png") no-repeat 0 0; }

.ico_lecture_report {
  overflow: hidden;
  display: block;
  width: 16px;
  height: 16px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/44/video_icon_02.png") no-repeat 0 0; }

.ico_lecture_report_g {
  overflow: hidden;
  display: block;
  width: 17px;
  height: 17px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/46/video_icon_04.png") no-repeat 0 0; }

.ico_vod_white {
  overflow: hidden;
  display: block;
  width: 45px;
  height: 45px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/47/video_icon_m3.png") no-repeat 0 0;
  background-size: 45px 45px; }

.ico_vod_white_lg {
  overflow: hidden;
  display: block;
  width: 70px;
  height: 70px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/42/video_icon_m2.png") no-repeat 0 0;
  background-size: 70px 70px; }

.ico_modal_close {
  display: inline-block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/49/area_x_1.png") no-repeat 0 0;
  background-size: 22px 22px; }

.ico_lock_gray {
  display: inline-block;
  width: 12px;
  height: 17px;
  overflow: hidden;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/48/video_icon_05.png") no-repeat 0 0;
  background-size: 12px 17px; }

/* detail top_bnr */
.top_bnr_wrap.employ_ver {
  position: relative;
  height: 188px; }
  .top_bnr_wrap.employ_ver:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(0, 0, 0, 0.32); }
  .top_bnr_wrap.employ_ver .top_bnr {
    position: relative;
    height: 188px; }
  .top_bnr_wrap.employ_ver .top_bnr_text {
    padding-top: 90px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px; }
  .top_bnr_wrap.employ_ver .company_info {
    padding-top: 48px; }
  .top_bnr_wrap.employ_ver .tit,
  .top_bnr_wrap.employ_ver .category {
    color: #fff;
    font-weight: normal; }
  .top_bnr_wrap.employ_ver .category {
    line-height: 18px; }
  .top_bnr_wrap.employ_ver a:hover {
    text-decoration: none; }

.employ_center_top {
  height: 250px;
  background-color: #c2c8cc;
  background-size: cover; }
  .employ_center_top .inner_wrap {
    position: relative;
    width: 1004px;
    height: 100%;
    margin: 0 auto;
    padding-top: 80px;
    box-sizing: border-box; }
  .employ_center_top .title {
    overflow: hidden;
    max-height: 80px;
    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
    color: #fff; }
  .employ_center_top .desc {
    overflow: hidden;
    width: 400px;
    max-height: 75px;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
    color: #fff;
    word-break: keep-all; }

.content_employ_review {
  width: 643px;
  padding: 2px 0 1px;
  background-color: #e6e6e6;
  box-sizing: border-box; }
  .content_employ_review .content_wrap {
    padding: 40px 20px 20px;
    background: #fff; }
  .content_employ_review .content_top {
    font-size: 22px;
    color: #333333;
    margin-bottom: 15px; }
    .content_employ_review .content_top strong {
      color: #00c362; }
    .content_employ_review .content_top .content_title {
      float: left;
      width: 480px;
      font-size: 22px;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .content_employ_review .content_top .content_title strong {
        color: #00c362; }
  .content_employ_review .content_bottom {
    padding-top: 20px;
    text-align: center; }
  .content_employ_review .btn_more {
    display: inline-block;
    width: 236px;
    height: 45px;
    line-height: 45px;
    background-color: #00c362;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff; }

.content_employ_thumb {
  position: relative;
  width: 605px;
  height: 340px;
  background-color: #2ac1bc;
  overflow: hidden; }
  .content_employ_thumb.employ_thumb_play .vod_wrap {
    display: block; }
  .content_employ_thumb.employ_thumb_play .btn_employ_play,
  .content_employ_thumb.employ_thumb_play .img_wrap,
  .content_employ_thumb.employ_thumb_play .btn_play,
  .content_employ_thumb.employ_thumb_play .vod_time {
    display: none; }
  .content_employ_thumb .vod_wrap,
  .content_employ_thumb .img_wrap {
    position: relative;
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    min-height: 307px;
    padding-top: 56.25%;
    background: #fff; }
  .content_employ_thumb .img_wrap {
    z-index: 1; }
    .content_employ_thumb .img_wrap img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      margin: 0 auto; }
    .content_employ_thumb .img_wrap:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: rgba(0, 0, 0, 0.2);
      content: "";
      z-index: 1; }
  .content_employ_thumb .vod_wrap > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border: 0 none; }
  .content_employ_thumb .btn_employ_play {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 5; }
  .content_employ_thumb .ico_vod_white_lg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    margin-top: -35px;
    margin-left: -35px;
    z-index: 10;
    text-indent: 100%;
    white-space: nowrap; }
  .content_employ_thumb .vod_time {
    position: absolute;
    right: 18px;
    bottom: 21px;
    padding: 8px 12px 7px;
    z-index: 10; }

.win {
  /* button */
  /* card footer */
  /* badge, tag */
  /* config list */
  /* top bnr (no react) */ }
  .win .jp_btn_line_green,
  .win .jp_btn_line_gray {
    padding: 8px 10px 12px; }
  .win .jp_btn_cancle,
  .win .jp_btn_buy,
  .win .jp_btn_disabled,
  .win .jp_btn_line_green_lg,
  .win .jp_btn_disabled_lg {
    padding: 10px 13px 16px; }
  .win .card_footer {
    padding: 8px 15px 0 15px; }
  .win .jp_badge,
  .win .jp_tag {
    padding: 3px 5px 5px; }
  .win .jp_tag {
    letter-spacing: -1.5px; }
  .win .goods_card_header .jp_tag {
    margin-top: 8px; }
  .win .content_config_list .ico_lecture_vod {
    margin-top: 3px; }
  .win .content_config_list .ico_lecture_report {
    margin-top: 4px; }
  .win .employ_buy_bar_component {
    /* config list */ }
    .win .employ_buy_bar_component .content_config_list {
      margin-top: 2px; }
      .win .employ_buy_bar_component .content_config_list .ico_lecture_vod {
        margin-top: 5px; }
      .win .employ_buy_bar_component .content_config_list .ico_lecture_report {
        margin-top: 6px; }
  .win .top_bnr_wrap.employ_ver .company_info {
    padding-top: 46px; }

/* layout */
.employ_center_page .body_wrap {
  position: relative;
  padding-top: 106px; }

.jp_section_group {
  width: 1004px;
  margin: 0 auto 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #b4b4b4; }
  .jp_section_group.border_bottom_none {
    border-bottom: 0; }

.employ_center_page .footer {
  margin-top: 60px; }

.employ_center_page.employ_center_detail .footer {
  margin-top: 20px;
  padding-bottom: 156px; }

.precautions_list {
  width: 1004px;
  margin: 60px auto 0; }
  .precautions_list > li {
    font-size: 16px;
    line-height: 30px;
    color: #666; }

@keyframes bounce_spinner {
  from {
    opacity: 1;
    transform: translateY(0); }
  to {
    opacity: 0.1;
    transform: translateY(-20px); } }

.react_button_wrap {
  min-width: 135px;
  display: inline-block; }

.react_employ_comingsoon_list_wrap {
  position: relative;
  padding-bottom: 60px; }

.react_employ_list_wrap {
  position: relative;
  padding-bottom: 100px; }

@keyframes round_ring_spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.employ_buy_shadow_bg:before {
  position: absolute;
  display: block;
  content: "";
  top: -10px;
  width: 100%;
  height: 10px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/50/employ_buy_shadow_bg.png") repeat-x 0 0;
  z-index: 10; }

.card_footer .content_config_list .icon {
  top: 2px; }

.employ_buy_bar_component {
  height: auto;
  width: 100%;
  position: relative;
  z-index: 10; }
  .employ_buy_bar_component.fixing .employ_buy_shadow_bg {
    position: relative !important; }
  .employ_buy_bar_component .content_config_list > li .icon {
    top: 1px; }

.win .employ_buy_bar_component .content_config_list > li .icon {
  top: 5px; }

/* 동영상 접근 카드 18.12.10 - yys */
.btn_cardview {
  float: right;
  line-height: 27px;
  font-size: 18px;
  font-weight: normal;
  color: #1e86ff; }
  .btn_cardview:hover {
    font-weight: bold;
    text-decoration: none; }

.content_cardview {
  padding: 1px 0;
  background-color: #e6e6e6; }
  .content_cardview .content_wrap {
    padding: 30px 20px 25px;
    background: #fff; }

.cardview_wrapper {
  position: relative;
  display: block; }
  .cardview_wrapper:hover {
    text-decoration: none; }
  .cardview_wrapper:hover .cardview_subject {
    text-decoration: underline; }

.content_cardview_thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.cardview_img {
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .cardview_img > img {
    width: 100%;
    min-height: 100%;
    vertical-align: middle; }
  .cardview_img:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.2);
    content: "";
    z-index: 1; }

.cardview_subject {
  margin-bottom: 8px;
  width: 100%;
  font-size: 16px;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.cardview_summary {
  line-height: 1.25em;
  height: 30px;
  font-size: 12px;
  color: #999;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis; }

.cardview_ty1 .cardview_wrapper {
  padding-top: 116px; }

.cardview_ty1 .cardview {
  float: left;
  margin-right: 20px;
  min-width: 187px;
  width: 30%;
  height: 173px; }
  .cardview_ty1 .cardview:last-child {
    margin-right: 0; }

.cardview_ty1 .content_cardview_thumb {
  height: 105px; }

.cardview_ty2 .cardview_wrapper {
  padding-top: 162px; }

.cardview_ty2 .cardview {
  height: 219px;
  margin-bottom: 20px; }

.cardview_ty2 .content_cardview_thumb {
  height: 147px; }

.cardview_ty3 .cardview_wrapper {
  padding-left: 115px; }

.cardview_ty3 .cardview {
  height: 60px;
  margin-bottom: 20px; }
  .cardview_ty3 .cardview:last-child {
    margin-bottom: 0; }

.cardview_ty3 .content_cardview_thumb {
  width: 105px;
  height: 60px; }

.side_content_cardview .side_hd {
  padding: 19px 19px 20px 19px; }

.side_content_cardview .side_body {
  padding: 0 19px; }

.side_btn_bottom {
  padding: 18px 19px; }

.win .side_content_cardview .txt_tits .lab_dtxt.new {
  top: 7px; }

.win .content_employ_review .content_top .content_title {
  width: 464px; }

.win .side_btn_bottom {
  padding: 21px 19px 20px; }

.content_employ_review .clearfix:after,
.content_cardview .clearfix:after,
.side_content_cardview .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.win .title_tag_wrap .title_right_tag {
  margin-top: -4px; }

.hr_floating_banner {
  overflow: hidden;
  display: none;
  width: 197px;
  height: 76px; }
  .hr_floating_banner.fixed {
    position: fixed;
    bottom: 5px;
    display: inline-block;
    z-index: 100; }
  .hr_floating_banner:hover img {
    margin-top: -76px; }
  .hr_floating_banner img {
    vertical-align: top;
    width: 100%;
    height: auto; }

@-webkit-keyframes hr_shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0); }
  40%, 60%, 80% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0); } }

@keyframes hr_shake {
  from, to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-3px, 0, 0);
    transform: translate3d(-3px, 0, 0); }
  40%, 60%, 80% {
    -webkit-transform: translate3d(3px, 0, 0);
    transform: translate3d(3px, 0, 0); } }

.hr_shake {
  animation: hr_shake 1s; }

/* 181204 회원가입 단계 팔로잉 기업 - yys */
.signUpStep1 .goodjobweb_btn .btn_ty1,
.signUpStep1_v3 .goodjobweb_btn .btn_ty1 {
  margin: 0;
  background: #00c362;
  border-color: #00c362; }
  .signUpStep1 .goodjobweb_btn .btn_ty1:hover,
  .signUpStep1_v3 .goodjobweb_btn .btn_ty1:hover {
    background: #00a85d;
    border-color: #00a85d; }

.signUpStep1 .question_box .following_company_list,
.signUpStep1_v3 .question_box .following_company_list {
  position: absolute;
  top: 100%;
  left: 0px;
  display: none;
  width: 100%;
  max-height: 288px;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 3px 8px 0 rgba(50, 52, 56, 0.15);
  z-index: 99999;
  cursor: default; }
  .signUpStep1 .question_box .following_company_list.active,
  .signUpStep1_v3 .question_box .following_company_list.active {
    display: block; }

.signUpStep1 .question_box .following_list_item,
.signUpStep1_v3 .question_box .following_list_item {
  padding: 16px;
  background-color: #fafafa; }

.question_box_ico_search {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 18px;
  height: 100%;
  padding: 0 20px;
  background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_search_181203.svg) no-repeat center; }

.goodjob_sign_up .goodjobweb_group .goodjob_header {
  margin-bottom: 15px; }

.goodjob_sign_up .goodjobweb_group .wrap_type2 {
  margin-bottom: 18px; }
  .goodjob_sign_up .goodjobweb_group .wrap_type2.top_bnr_wrap {
    margin-bottom: 10px;
    background-color: #fff; }

.goodjob_sign_up .iptwrap_ty1 .input_ty1 {
  padding: 0 40px 0 13px; }

.goodjob_sign_up .goodjob_subtitle {
  text-align: left;
  line-height: 19px;
  font-size: 15px;
  color: #424242; }

.random_company_wrapper {
  position: relative;
  height: 297px;
  padding: 26px 16px 0px;
  border: 1px solid #ccc;
  border-radius: 2px; }
  .random_company_wrapper .following_refresh_btn {
    position: absolute;
    top: 26px;
    right: 16px; }
    .random_company_wrapper .following_refresh_btn .refresh_btn {
      line-height: 19px;
      font-size: 13px;
      color: #686a6d; }
      .random_company_wrapper .following_refresh_btn .refresh_btn:hover {
        text-decoration: underline; }
      .random_company_wrapper .following_refresh_btn .refresh_btn:before {
        content: "";
        display: inline-block;
        width: 13px;
        height: 16px;
        margin-right: 5px;
        vertical-align: sub;
        background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_refresh_181203.svg) no-repeat center;
        background-size: cover; }

.following_company_list {
  padding: 10px 0; }

.following_list_item {
  padding: 16px 0; }
  .following_list_item:after {
    content: "";
    display: table;
    clear: both; }
  .following_list_item .mlogo,
  .following_list_item .following_item_txt {
    float: left; }
  .following_list_item .mlogo {
    margin-right: 13px;
    width: 40px;
    height: 40px; }
    .following_list_item .mlogo img {
      vertical-align: inherit; }
  .following_list_item .following_item_txt {
    width: 237px; }
  .following_list_item .tit_des2 {
    margin-top: 4px;
    font-weight: normal; }
  .following_list_item .txt_des {
    margin-top: 3px; }
  .following_list_item .btn_follow_company {
    float: right;
    min-width: 66px;
    margin-top: 6px;
    margin-right: 0;
    padding-left: 23px;
    padding-right: 7px;
    border: 1px solid #c5c7cc;
    font-size: 13px;
    color: #616161;
    background-color: #fff;
    border-radius: 3px;
    line-height: 25px; }
  .following_list_item .ico_add {
    position: absolute;
    top: 6px;
    left: 7px;
    width: 12px;
    height: 12px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_follow_add_181214.svg) no-repeat 0 0;
    background-size: 12px 12px; }

.following_com_item {
  margin: 8px 8px 0 0;
  display: inline-block;
  padding: 13px 15px 11px;
  background: #f3f3f4;
  border-radius: 3px;
  font-size: 13px;
  color: #323438;
  line-height: 16px; }

.btn_unset_item {
  margin-left: 13px;
  display: inline-block;
  width: 11px;
  height: 11px;
  background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/icon_unfollow_181203.svg) no-repeat center;
  background-size: cover;
  vertical-align: top;
  margin-top: 2px; }

.win .following_item_txt {
  width: 218px; }

.win .following_com_item {
  padding: 10px 15px 14px; }

.win .following_list_item .tit_des2 {
  margin-top: 2px;
  font-weight: normal; }

.win .following_list_item .txt_des {
  margin-top: 4px; }

.win .btn_follow_company {
  letter-spacing: -1px;
  line-height: 20px; }

.win .btn_unset_item {
  margin-top: 5px; }

.react-autosuggest__input {
  display: inline-block;
  height: 38px;
  width: 100%;
  padding: 0 40px 0 13px;
  border: 1px solid #cccccc;
  -webkit-box-size: border-box;
  -moz-box-size: border-box;
  -ms-box-size: border-box;
  -o-box-size: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 13px;
  font-weight: normal;
  background-color: #fafafa; }

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  left: 0px;
  display: none;
  width: 100%;
  height: auto;
  max-height: 288px;
  margin: 0;
  padding: 0;
  background-color: white;
  border: solid 1px #c9c9c9;
  box-sizing: border-box;
  box-shadow: 0 3px 8px 0 rgba(50, 52, 56, 0.15);
  z-index: 99999;
  cursor: default;
  overflow-y: auto;
  overflow-x: hidden; }
  .react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open {
    display: block; }

#employcenter {
  padding-top: 54px; }
  #employcenter .jp_lnb.sticky {
    top: 0; }
